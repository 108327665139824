export const config = {
  java_api_url: "https://v2.cryptoveche.ru/api",
  auth_api_url: "https://v2.cryptoveche.ru/auth",
  client_url: "https://v2.cryptoveche.ru",
  enable_esia: false,
  ws_connect: "wss://v2.cryptoveche.ru/ws",
  ws_pass: "client",
  ws_user: "client",
  type: "main",
  realm: "REALM_CRYPTOVECHE",
  header_view: "", // "edro"
  localStorageVersion: 2024072401
};
